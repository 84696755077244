export const firebaseConfig = {
  apiKey: 'AIzaSyAw61ZiMTYAKkXywbwJJh32mGOP8gVGClc',
  authDomain: 'vsmrk-dashboard.firebaseapp.com',
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: 'vsmrk-dashboard',
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: '1:614321050946:web:7d6c686711c54f287364db',
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
}
