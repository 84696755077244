import React from 'react'
import { createRoot } from 'react-dom/client'

import { ApolloProvider } from '@apollo/client'
import { client } from 'apollo/apolloClient'

import { ConfigProvider } from 'antd'
import ruRU from 'antd/lib/locale/ru_RU'
import 'moment/locale/ru'

import './index.css'
import './index.sass'
import App from './App'

import { GlobalStateProvider } from 'providers/GlobalStateProvider'
import { DragAndDropProvider } from 'providers/DragAndDropProvider'

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <React.StrictMode>
    <GlobalStateProvider>
      <ConfigProvider locale={ruRU}>
        <ApolloProvider client={client}>
          <DragAndDropProvider>
            <App />
          </DragAndDropProvider>
        </ApolloProvider>
      </ConfigProvider>
    </GlobalStateProvider>
  </React.StrictMode>
)
