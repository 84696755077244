/* eslint-disable @typescript-eslint/no-unused-vars */
import { createMachine } from 'xstate'
import { checkIsAuth, signIn } from '../actions/authActions'

type AuthMachineContext = {
  errorMessage: string | null
}

export const authMachine = createMachine<AuthMachineContext>({
  predictableActionArguments: true,
  schema: { context: {} as AuthMachineContext },
  context: { errorMessage: null },
  id: 'authMachine',
  initial: 'checkingIsAuth',
  states: {
    checkingIsAuth: {
      invoke: {
        src: checkIsAuth,
        id: 'checkingIsAuth',
      },
      on: {
        AUTHENTICATED: {
          target: 'authenticated',
        },
        NOT_AUTHENTICATED: {
          target: 'notAuthenticated',
        },
      },
    },
    authenticated: {},
    notAuthenticated: {
      initial: 'idle',
      states: {
        idle: {
          on: {
            TRY_LOG_IN: {
              target: 'signingIn',
            },
          },
        },
        signingIn: {
          invoke: {
            src: signIn,
            id: 'signIn',
            onDone: [
              {
                target: '#authMachine.checkingIsAuth',
              },
            ],
            onError: [
              {
                target: 'error',
              },
            ],
          },
        },
        error: {
          on: {
            TRY_LOG_IN: {
              target: 'signingIn',
            },
          },
        },
      },
    },
  },
})
