/* eslint-disable @typescript-eslint/no-unused-vars */
import { firebaseConfig } from 'services/firebaseConfig'
import { initializeApp } from 'firebase/app'
import {
  getAuth,
  signInWithEmailAndPassword,
  browserLocalPersistence,
  setPersistence,
  onAuthStateChanged,
} from 'firebase/auth'

initializeApp(firebaseConfig)
const auth = getAuth()

export const checkIsAuth = (context, event) => (callback) => {
  const auth = getAuth()
  onAuthStateChanged(auth, (user) => {
    if (user) {
      callback({ type: 'AUTHENTICATED' })
    } else {
      callback({ type: 'NOT_AUTHENTICATED' })
    }
  })
}

export const signIn = async (context, event) => {
  try {
    const { username, password } = event.values
    await setPersistence(auth, browserLocalPersistence)
    await signInWithEmailAndPassword(auth, username, password)
  } catch (err) {
    const messages = {
      'auth/user-not-found': 'Пользователя не существует',
      'auth/wrong-password': 'Неверный пароль',
      'auth/too-many-requests': 'Превышен лимит запросов',
    }
    const errorMessage = messages[err.code] || 'Ошибка входа'
    throw new Error(errorMessage)
  }
}
