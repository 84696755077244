import { firebaseConfig } from 'services/firebaseConfig'
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

initializeApp(firebaseConfig)
const auth = getAuth()

export const getToken = async () => {
  try {
    const tokenId = await auth.currentUser.getIdToken()
    return `Bearer ${tokenId}`
  } catch (error) {
    console.error(error)
    throw error
  }
}
