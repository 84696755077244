import React, { Suspense, useContext } from 'react'
import { BrowserRouter } from 'react-router-dom'

import { useActor, useSelector } from '@xstate/react'
import { GlobalStateContext } from 'providers/GlobalStateProvider'

import { Spin } from 'antd'

const LoginPage = React.lazy(() => import('./pages/LogInPage'))
const Dashboard = React.lazy(() => import('./components'))

const loggedInSelector = (state) => {
  return state.matches('authenticated')
}

const loggedOutSelector = (state) => {
  return state.matches('notAuthenticated')
}

const Loading = () => (
  <div
    style={{
      position: 'absolute',
      left: '50%',
      top: '50%',
    }}
  >
    <Spin size="large" tip="Загрузка" />
  </div>
)

function App() {
  const globalService = useContext(GlobalStateContext)
  const [authMachine, sendToAuthMachine] = useActor(globalService.authService)

  const isLoggedIn = useSelector(globalService.authService, loggedInSelector)
  const isLoggedOut = useSelector(globalService.authService, loggedOutSelector)

  return (
    <BrowserRouter>
      <div>
        <Suspense fallback={<Loading />}>
          {isLoggedIn ? (
            <Dashboard />
          ) : isLoggedOut ? (
            <LoginPage
              machine={authMachine}
              sendToMachine={sendToAuthMachine}
            />
          ) : (
            <Loading />
          )}
        </Suspense>
      </div>
    </BrowserRouter>
  )
}

export default App
