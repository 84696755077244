import React, { createContext } from 'react'

import { useInterpret } from '@xstate/react'
import { ActorRefFrom } from 'xstate'

import { authMachine } from 'machines/authMachine'

interface GlobalStateContextType {
  authService: ActorRefFrom<typeof authMachine>
}

export const GlobalStateContext = createContext({} as GlobalStateContextType)

export const GlobalStateProvider = (props) => {
  const authService = useInterpret(authMachine)

  return (
    <GlobalStateContext.Provider value={{ authService }}>
      {props.children}
    </GlobalStateContext.Provider>
  )
}
