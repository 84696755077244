import React from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

export const DragAndDropProvider = ({ children }): JSX.Element => {
  return (
    <DndProvider backend={HTML5Backend} key={1}>
      {children}
    </DndProvider>
  )
}
