import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { getToken } from 'services/getToken'

const API_GRAPHQL = process.env.REACT_APP_API_GRAPHQL

const httpLink = createHttpLink({
  uri: API_GRAPHQL,
  // fetchOptions: {
  //   credentials: 'include',
  // },
})

const authLink = setContext(async (_, { headers }) => {
  const token = await getToken()
  return {
    headers: {
      ...headers,
      authorization: token ? token : '',
    },
  }
})

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
})
